import * as React from 'react';
import * as R from 'ramda';

// @ts-ignore
import basicElementIcon from '@atom/components/common/svgIcons/elementIcon.svg';
import { Icon, Menu, Modal } from '@atom/mui';
import {
  buildFhwaUpdateBody,
  equalizeFhwaPercentValues,
  equalizeFhwaValues,
  isFhwaValid,
} from '@atom/selectors/formModuleSelectors';
import {
  DEFECT_MARKER_ID,
  END_JOINT_MARKER_ID,
  PROTECTIVE_COATING_MARKER_ID,
  WEARING_SURFACE_MARKER_ID,
} from '@atom/selectors/schemaSelectors';
import colors from '@atom/styles/colors';
import { EventType } from '@atom/types/event';
import { FhwaModuleElementType } from '@atom/types/form';
import { MediaItem } from '@atom/types/media';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import iconUtilities from '@atom/utilities/iconUtilities';

import FhwaAddAssetElementModal from './FhwaAddAssetElementModal';
import FhwaDropDown from './FhwaDropDown';
import FhwaInput from './FhwaInput';
import FhwaMediaModal from './FhwaMediaModal';
import FhwaRemarksModal from './FhwaRemarksModal';

import '../formInstance.css';

const { MenuItem } = Menu;

interface PassedProps {
  csType: string;
  element: FhwaModuleElementType;
  isEditable: boolean;
  fieldId: string;
  assetId: string;
  media: MediaItem[];
  isUploadDisabled: boolean;
  indentLevel: number;
  elementPath: any[];
  uploadFiles: (
    files?: any[],
    subjectId?: string,
    subjectType?: string,
  ) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  progressiveUpdateFormInstanceFhwa: (
    fieldId: string,
    assetId: string,
    body: Object,
    elementPath: any[],
  ) => void;
  onBatchCreate: (payload: Object) => void;
  onElementDelete: (assetId: string) => boolean;
}

type Props = PassedProps;

enum ModalType {
  ADD_DEFECT = 'ADD_DEFECT',
  ADD_PROTECTIVE_COATING = 'ADD_PROTECTIVE_COATING',
  ADD_END_JOINT = 'ADD_END_JOINT',
  ADD_WEARING_SURFACE = 'ADD_WEARING_SURFACE',
  DELETE = 'DELETE',
  NONE = 'NONE',
}

interface State {
  environment: string;
  totalQuantity: number;
  remarks: string;
  cs1: number;
  cs2: number;
  cs3: number;
  cs4: number;
  cs1Percent: number;
  cs2Percent: number;
  cs3Percent: number;
  cs4Percent: number;
  openModal: { type: ModalType; markerId: number; id: any };
}

class FhwaFieldRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { attributes } = this.props.element;
    this.state = {
      environment: R.pathOr(null, ['value'], attributes.environment),
      totalQuantity: R.pathOr(0, ['value'], attributes.totalQuantity),
      remarks: R.pathOr('', ['value'], attributes.remarks),
      cs1: R.pathOr(0, ['value'], attributes.cs1),
      cs2: R.pathOr(0, ['value'], attributes.cs2),
      cs3: R.pathOr(0, ['value'], attributes.cs3),
      cs4: R.pathOr(0, ['value'], attributes.cs4),
      cs1Percent: R.pathOr(0, ['value'], attributes.cs1Percent),
      cs2Percent: R.pathOr(0, ['value'], attributes.cs2Percent),
      cs3Percent: R.pathOr(0, ['value'], attributes.cs3Percent),
      cs4Percent: R.pathOr(0, ['value'], attributes.cs4Percent),
      openModal: { type: ModalType.NONE, markerId: 0, id: null },
    };
  }

  toggleModal = (modalType: ModalType, markerId: number, openId: any) => {
    this.setState({ openModal: { type: modalType, markerId, id: openId } });
  };

  isModalOpen = (modalTypeArr: ModalType[], openId: any) => {
    const { type, id } = this.state.openModal;
    return modalTypeArr.includes(type) && id === openId;
  };

  onDropDownChange = async (event: any) => {
    const { value } = event.target;

    await this.setState({ environment: value });

    this.saveFhwa();
  };

  onChange = async (event: EventType) => {
    const { csType } = this.props;
    const { name: attributeName, value } = event.target;

    const newValue = value === '' ? '' : Number(value);

    // @ts-ignore
    await this.setState({ [attributeName]: newValue });

    const equalizedValues =
      csType === '%'
        ? equalizeFhwaPercentValues(this.state)
        : equalizeFhwaValues(this.state);

    // @ts-ignore
    await this.setState({ ...equalizedValues });

    this.saveFhwa();
  };

  saveRemarks = async (remarks: string) => {
    await this.setState({ remarks });

    this.saveFhwa();
  };

  saveFhwa = () => {
    const {
      progressiveUpdateFormInstanceFhwa,
      fieldId,
      assetId,
      element,
      elementPath,
    } = this.props;

    const body = buildFhwaUpdateBody(this.state, element);

    progressiveUpdateFormInstanceFhwa(fieldId, assetId, body, elementPath);
  };

  assetFileUpload = (event: EventType) => {
    const { uploadFiles, assetId } = this.props;

    const files = event.target.files;
    const subjectType = 'formInstanceAsset';

    uploadFiles(files, assetId, subjectType);
  };

  getIndentStyle = (): Object => {
    const { indentLevel } = this.props;

    const BASE_INDENT_REMS = 0.75;
    const NESTED_INDENT_REMS = 1.75;

    const indentPadding = NESTED_INDENT_REMS * indentLevel;

    return {
      paddingLeft: `${BASE_INDENT_REMS + indentPadding}rem`,
    };
  };

  onDelete = () => {
    const { assetId, onElementDelete } = this.props;

    onElementDelete(assetId);
  };

  onConfirmDeleteModal = () => {
    this.onDelete();
    this.toggleModal(ModalType.NONE, 0, null);
  };

  getModalTitle = (modalType: ModalType) => {
    switch (modalType) {
      case ModalType.ADD_DEFECT:
        return 'Add Defect';
      case ModalType.ADD_PROTECTIVE_COATING:
        return 'Add Protective Coating';
      case ModalType.ADD_END_JOINT:
        return 'Add End Joint';
      case ModalType.ADD_WEARING_SURFACE:
        return 'Add Wearing Surface';
      default:
        return '';
    }
  };

  render() {
    const {
      element,
      csType,
      isEditable,
      isUploadDisabled,
      media,
      assetId,
      removeFile,
      renameMedia,
      progressiveUpdateFormInstanceFhwa,
      uploadFiles,
      fieldId,
      indentLevel,
      elementPath,
      onBatchCreate,
      onElementDelete,
    } = this.props;
    const {
      environment,
      totalQuantity,
      cs1,
      cs2,
      cs3,
      cs4,
      cs1Percent,
      cs2Percent,
      cs3Percent,
      cs4Percent,
      remarks,
      openModal,
    } = this.state;

    const csName = csType === '%' ? 'Percent' : '';

    const cs1Value = csType === '%' ? cs1Percent : cs1;
    const cs2Value = csType === '%' ? cs2Percent : cs2;
    const cs3Value = csType === '%' ? cs3Percent : cs3;
    const cs4Value = csType === '%' ? cs4Percent : cs4;

    const isValid = isFhwaValid(this.state);

    const hasRemarks = !R.isEmpty(remarks);

    const nameStyles = isValid ? '' : 'red';

    const unit = R.pathOr('', ['unit'], element.attributes.totalQuantity);

    const nestedElements = R.pathOr({}, ['elements'], element);
    const order = R.pathOr([], ['order'], element);

    const existingSchemaIdsList = order.map(id => nestedElements[id]?.schemaId);
    const existingSchemaIds = existingSchemaIdsList.reduce((id, index) => {
      id[index] = true;
      return id;
    }, {});

    const leftIcon = !R.isEmpty(nestedElements) ? (
      <Icon color={colors.neutral.gray} style={{ paddingRight: '5px' }}>
        keyboard_arrow_up
      </Icon>
    ) : (
      <div style={{ height: '24px', width: '24px', paddingRight: '5px' }} />
    );

    const elementIcon = iconUtilities.buildFHWAElementIcon(
      element.markerId,
      isValid,
    );

    const leftIndentStyle = this.getIndentStyle();

    const renderAddDefect =
      (element.markerId === PROTECTIVE_COATING_MARKER_ID ||
        R.isNil(element.markerId)) &&
      !isCurrentClient([Client.CBIT]);
    const renderAddProtectiveCoating = R.isNil(element.markerId);
    const renderAddEndJoint = isCurrentClient([Client.CBIT]);
    const renderAddWearingSurface = isCurrentClient([Client.CBIT]);

    return (
      <React.Fragment>
        <div styleName="fhwa-table-row">
          <div styleName="element-column" style={leftIndentStyle}>
            <div styleName="fhwa-left-icon-box">
              {leftIcon}
              {elementIcon}
            </div>
            <div styleName={`element-name ${nameStyles}`}>{element.name}</div>
          </div>
          <div styleName="large-column environment">
            <FhwaDropDown
              isEditable={isEditable}
              element={element}
              environment={environment}
              onChange={this.onDropDownChange}
            />
          </div>
          <div styleName="large-column">
            <FhwaInput
              name="totalQuantity"
              value={totalQuantity}
              isEditable={isEditable}
              onChange={this.onChange}
              isValid={isValid}
              width="5rem"
            />
            <div styleName="column-suffix-text">{unit}</div>
          </div>
          <div styleName="cs1-column">{cs1Value}</div>
          <div styleName="normal-column">
            <FhwaInput
              name={`cs2${csName}`}
              value={cs2Value}
              isEditable={isEditable}
              onChange={this.onChange}
              isValid={isValid}
              width="3.8rem"
            />
          </div>
          <div styleName="normal-column">
            <FhwaInput
              name={`cs3${csName}`}
              value={cs3Value}
              isEditable={isEditable}
              onChange={this.onChange}
              isValid={isValid}
              width="3.8rem"
            />
          </div>
          <div styleName="normal-column">
            <FhwaInput
              name={`cs4${csName}`}
              value={cs4Value}
              isEditable={isEditable}
              onChange={this.onChange}
              isValid={isValid}
              width="3.8rem"
            />
          </div>
          <div styleName="attach-column">
            <FhwaMediaModal
              isUploadDisabled={isUploadDisabled}
              isEditable={isEditable}
              media={media}
              assetFileUpload={this.assetFileUpload}
              removeFile={removeFile}
              assetId={assetId}
              renameMedia={renameMedia}
            />
          </div>
          <div styleName="attach-column right">
            <FhwaRemarksModal
              remarks={remarks}
              isEditable={isEditable}
              saveRemarks={this.saveRemarks}
              hasRemarks={hasRemarks}
            />
          </div>
          <div styleName="attach-column additional">
            {isEditable && (
              <Menu>
                {renderAddDefect && (
                  <MenuItem
                    startAdornment={<Icon>report_problem</Icon>}
                    onClick={() =>
                      this.toggleModal(
                        ModalType.ADD_DEFECT,
                        DEFECT_MARKER_ID,
                        assetId,
                      )
                    }
                  >
                    Add Defect
                  </MenuItem>
                )}
                {renderAddProtectiveCoating && (
                  <MenuItem
                    startAdornment={<Icon>verified_user</Icon>}
                    onClick={() =>
                      this.toggleModal(
                        ModalType.ADD_PROTECTIVE_COATING,
                        PROTECTIVE_COATING_MARKER_ID,
                        assetId,
                      )
                    }
                  >
                    Add Protective Coating
                  </MenuItem>
                )}
                {renderAddEndJoint && (
                  <MenuItem
                    startAdornment={
                      <Icon>
                        <img src={basicElementIcon} />
                      </Icon>
                    }
                    onClick={() =>
                      this.toggleModal(
                        ModalType.ADD_END_JOINT,
                        END_JOINT_MARKER_ID,
                        assetId,
                      )
                    }
                  >
                    Add End Joint
                  </MenuItem>
                )}
                {renderAddWearingSurface && (
                  <MenuItem
                    startAdornment={
                      <Icon>
                        <img src={basicElementIcon} />
                      </Icon>
                    }
                    onClick={() =>
                      this.toggleModal(
                        ModalType.ADD_WEARING_SURFACE,
                        WEARING_SURFACE_MARKER_ID,
                        assetId,
                      )
                    }
                  >
                    Add Wearing Surface
                  </MenuItem>
                )}
                <MenuItem
                  startAdornment={<Icon>delete</Icon>}
                  onClick={() => this.toggleModal(ModalType.DELETE, 0, assetId)}
                >
                  Delete
                </MenuItem>
              </Menu>
            )}
          </div>
        </div>
        {order.map((nestedAssetId: string, index: number) => {
          const nextElementPath = [...elementPath, 'elements', nestedAssetId];
          const rowMedia = media.filter(
            medium => medium.fieldId === nestedAssetId,
          );

          return (
            <FhwaFieldRow
              csType={csType}
              key={`${nestedAssetId}-${index}`}
              element={nestedElements[nestedAssetId]}
              assetId={nestedAssetId}
              fieldId={fieldId}
              isEditable={isEditable}
              media={rowMedia}
              isUploadDisabled={isUploadDisabled}
              uploadFiles={uploadFiles}
              removeFile={removeFile}
              renameMedia={renameMedia}
              progressiveUpdateFormInstanceFhwa={
                progressiveUpdateFormInstanceFhwa
              }
              indentLevel={indentLevel + 1}
              elementPath={nextElementPath}
              onBatchCreate={onBatchCreate}
              onElementDelete={onElementDelete}
            />
          );
        })}
        <Modal
          open={this.isModalOpen([ModalType.DELETE], assetId)}
          onCancel={() => this.toggleModal(ModalType.NONE, 0, null)}
          title={`Delete ${element.name}?`}
          confirmButtonText="Delete"
          ConfirmButtonProps={{ style: { background: colors.brand.red } }}
          onConfirm={this.onConfirmDeleteModal}
        >
          Are you sure you want to delete this element and all nested elements?
        </Modal>
        {/* @ts-ignore */}
        <FhwaAddAssetElementModal
          open={this.isModalOpen(
            [
              ModalType.ADD_DEFECT,
              ModalType.ADD_PROTECTIVE_COATING,
              ModalType.ADD_END_JOINT,
              ModalType.ADD_WEARING_SURFACE,
            ],
            assetId,
          )}
          closeModal={() => this.toggleModal(ModalType.NONE, 0, null)}
          title={this.getModalTitle(this.state.openModal.type)}
          assetId={assetId}
          assetName={element.name}
          onBatchCreate={onBatchCreate}
          existingSchemaIds={existingSchemaIds}
          markerId={openModal.markerId}
        />
      </React.Fragment>
    );
  }
}

export default FhwaFieldRow;
