import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'client';

import { clearApplicationSearch } from '@atom/actions/applicationSearchActions';
import { toggleNavigationMenu } from '@atom/actions/menuStateActions';
import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import history from '@atom/utilities/history';

import SearchResults from './SearchResults';

// @ts-ignore
import navStyles from '../navigation.css';
// @ts-ignore
import styles from './searchBar.css';

/* Note that this is to satisfy the webpack build step which would
   automatically de-reference/scrub the import of styles as they arent
   used anywhere in the code.  Passing them as magic strings to styleName
   is irrelevant

   For additional reading:
   https://github.com/gajus/babel-plugin-react-css-modules/issues/130
*/

// @ts-ignore
let styleReference = styles;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
styleReference = navStyles;

const iconStyles = {
  width: '24px',
  height: '24px',
  color: colors.neutral.gray,
  fontWeight: '500',
};

const SearchBar = () => {
  const dispatch = useDispatch();
  const menuState = useSelector((state: RootState) => state.menuState);
  const appSearchQuery = useSelector(
    (state: RootState) => state.applicationSearch.query,
  );

  const toggleMenu = (): void => {
    dispatch(toggleNavigationMenu(menuState, 'search'));
  };

  const clearSearch = () => {
    dispatch(clearApplicationSearch());
  };

  const navigate = () => {
    clearSearch();
    toggleMenu();
    history.push(`/search?query=${appSearchQuery}`);
  };

  const isSearchPortal = !!window.location.pathname.includes('search');
  const placeholderText = appSearchQuery ? appSearchQuery : 'Search';

  return (
    <div styleName="styles.static-search-bar-container" onClick={toggleMenu}>
      {menuState.search && (
        <div onClick={toggleMenu} styleName="navStyles.mask" />
      )}
      <div styleName="styles.static-search-bar-icon">
        <Icon style={iconStyles}>search</Icon>
      </div>
      <div styleName="styles.static-search-bar-text">{placeholderText}</div>
      {menuState.search && (
        <SearchResults navigate={navigate} isSearchPortal={isSearchPortal} />
      )}
    </div>
  );
};

export default SearchBar;
