import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as inventoryAssetActionCreators from '@atom/actions/inventoryAssetActions';
import FontIconButton from '@atom/components/common/FontIconButton';
// @ts-ignore
import percentageIcon from '@atom/components/common/svgIcons/percentageIcon.svg';
import { Icon } from '@atom/mui';
import { fhwaFormModule } from '@atom/selectors/formModuleSelectors';
import colors from '@atom/styles/colors';
import { InventoryAssetActions } from '@atom/types/actions';
import { FormInstanceFieldItem } from '@atom/types/form';
import { InventorySchemaItem } from '@atom/types/inventory';
import { MediaItem } from '@atom/types/media';

import FhwaAddElementModal from './FhwaAddElementModal';
import FhwaFieldRow from './FhwaFieldRow';
import { FHWA_PREVIEW_MOCK } from './fhwaPreviewMock';

import '../formInstance.css';

const styles = {
  icon: {
    padding: '0px',
    height: '24px',
    width: '24px',
  },
  addIcon: {
    marginLeft: '1rem',
    padding: '0px',
    height: '24px',
    width: '24px',
  },
};

interface PassedProps {
  field: FormInstanceFieldItem;
  fieldId: string;
  rootSchema: InventorySchemaItem;
  formInstanceId: string;
  preview: boolean;
  isEditable: boolean;
  media: MediaItem[];
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  inventoryAssetId: string;
  isUploadDisabled: boolean;
  uploadFiles: (
    files?: any[],
    subjectId?: string,
    subjectType?: string,
  ) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  progressiveUpdateFormInstanceFhwa: (
    fieldId: string,
    assetId: string,
    body: Object,
    elementPath: any[],
  ) => void;
}

interface ReduxDispatchProps {
  inventoryAssetActions: InventoryAssetActions;
}

type Props = PassedProps & ReduxDispatchProps;

interface State {
  csType: string;
}

class FhwaField extends React.Component<Props, State> {
  state = {
    csType: '',
  };

  swapType = () => {
    const { csType } = this.state;

    this.setState({ csType: csType === '%' ? '' : '%' });
  };

  onBatchCreate = (payload: Object) => {
    const {
      inventoryAssetActions,
      inventoryAssetId,
      formInstanceId,
    } = this.props;
    inventoryAssetActions.requestRootAssetBatchCreateFHWA({
      ...payload,
      formInstanceId,
      rootAssetId: inventoryAssetId,
    });
  };

  onElementDelete = (assetId: string): boolean => {
    const { inventoryAssetActions, formInstanceId } = this.props;

    inventoryAssetActions.requestFhwaElementDeletion({
      assetId,
      formInstanceId,
    });

    return true;
  };

  render() {
    const {
      preview,
      field,
      fieldId,
      media,
      uploadFiles,
      removeFile,
      renameMedia,
      isEditable,
      isUploadDisabled,
      progressiveUpdateFormInstanceFhwa,
      inventoryAssetId,
      rootSchema,
    } = this.props;
    const { csType } = this.state;

    const suffix = csType === '%' ? '(%)' : '';

    const swapIcon =
      csType === '%' ? (
        <FontIconButton
          style={styles.icon}
          onClick={this.swapType}
          color={colors.neutral.gray}
          icon="looks_one"
        />
      ) : (
        <img
          styleName="percentage-icon"
          src={percentageIcon}
          alt="Help"
          onClick={this.swapType}
        />
      );

    const fhwaForm = preview ? FHWA_PREVIEW_MOCK : field;
    const order = R.pathOr([], ['order'], fhwaForm);
    const elements = R.pathOr({}, ['elements'], fhwaForm);

    const existingSchemaIdList = order.map(
      assetId => elements[assetId].schemaId,
    );
    const existingSchemaIds = existingSchemaIdList.reduce((id, index) => {
      id[index] = true;
      return id;
    }, {});

    return (
      <div styleName="fhwa-container">
        <div styleName="fhwa-header">
          <div styleName="fhwa-icons">
            <Icon color={colors.brand.blue}>{fhwaFormModule.icon}</Icon>
            <div styleName="fhwa-title">{fhwaFormModule.title}</div>
          </div>
          <div styleName="fhwa-icons">
            {swapIcon}
            {isEditable && (
              <FhwaAddElementModal
                title="Add elements to asset"
                assetId={inventoryAssetId}
                schemaId={rootSchema.id}
                assetName={rootSchema.name}
                // @ts-ignore
                onBatchCreateAction={this.onBatchCreate}
                existingSchemaIds={existingSchemaIds}
                isFhwaForm
              />
            )}
          </div>
        </div>
        <div styleName="fhwa-table-header">
          <div styleName="element-column header">sub item</div>
          <div styleName="large-column environment">environment</div>
          <div styleName="large-column">total qty.</div>
          <div styleName="cs1-header-column">{`cs1 ${suffix}`}</div>
          <div styleName="normal-column">{`cs2 ${suffix}`}</div>
          <div styleName="normal-column">{`cs3 ${suffix}`}</div>
          <div styleName="normal-column">{`cs4 ${suffix}`}</div>
          <div styleName="attach-column">photos</div>
          <div styleName="attach-column right">notes</div>
          <div styleName="attach-column additional" />
        </div>
        <div styleName="fhwa-rows-container">
          {order.map((assetId: string, index: number) => {
            const fieldRowMedia = media.filter(
              medium => medium.fieldId === assetId,
            );
            const row = (
              <div styleName="fhwa-row-block" key={index}>
                <FhwaFieldRow
                  csType={csType}
                  key={`${assetId}-${index}`}
                  element={elements[assetId]}
                  assetId={assetId}
                  fieldId={fieldId}
                  isEditable={isEditable}
                  isUploadDisabled={isUploadDisabled}
                  uploadFiles={uploadFiles}
                  media={fieldRowMedia}
                  renameMedia={renameMedia}
                  removeFile={removeFile}
                  progressiveUpdateFormInstanceFhwa={
                    progressiveUpdateFormInstanceFhwa
                  }
                  indentLevel={0}
                  elementPath={[assetId]}
                  onBatchCreate={this.onBatchCreate}
                  onElementDelete={this.onElementDelete}
                />
              </div>
            );

            const isFhwaValid = !R.isNil(elements) && !R.isEmpty(elements);

            return isFhwaValid ? row : <div />;
          })}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inventoryAssetActions: bindActionCreators(
    inventoryAssetActionCreators,
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(FhwaField);
