import client from '@atom/graph/client';
import configuration from '@atom/utilities/configuration';
import { redirectDomain, subdomain } from '@atom/utilities/endpoints';
import fullStoryUtilities from '@atom/utilities/fullstoryUtilities';
import history from '@atom/utilities/history';

import { configureStore } from './store/configureStore';
import Root from './Root';
import rootSaga from './sagas';

import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import './styles/override/calendar.css';
import './styles/iconfont/material-icons.css';

import './images/favicons/favicon.ico';
import './images/favicons/apple-touch-icon.png';
import './images/favicons/favicon-16x16.png';
import './images/favicons/favicon-32x32.png';
import './images/favicons/safari-pinned-tab.svg';
import './images/email-atom-logo.png';
import './images/email-banner-image.png';

if (configuration.environment === 'production') {
  fullStoryUtilities.instantiateFullStory();
}

// @ts-ignore
const store = configureStore();
export type RootState = ReturnType<typeof store.getState>;
// @ts-ignore
store.runSaga(rootSaga);

// If a user lands on the subdomainless page, they will be redirected
// to the www domain.
if (!subdomain) {
  window.location.replace(redirectDomain);
}

if (configuration.env === configuration.constants.LOCAL) {
  document.title = `LOCAL - ${document.title}`;
} else if (configuration.env === configuration.constants.DEV) {
  document.title = `DEVELOPMENT - ${document.title}`;
} else if (configuration.env === configuration.constants.QA) {
  document.title = `QA - ${document.title}`;
} else if (configuration.env === configuration.constants.UAT) {
  document.title = `UAT - ${document.title}`;
}

Root.initializeApplication(store, history.getHistory(), client);
