import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'client';

import { updateTeamNavigation } from '@atom/actions/teamNavigationActions';
import {
  baseUserGroup,
  initialPayPeriodYear,
  Section,
  View,
} from '@atom/components/teamPortal/TeamContext';
import { PayPeriod } from '@atom/types/payPeriod';
import { UserGroupTreeType } from '@atom/types/userGroups';

export interface TeamNavigation {
  expanded: Set<Section>;
  activeView: View;
  activeGroup: UserGroupTreeType;
  activePath: UserGroupTreeType[];
  activeTimeSheetGroup: any;
  payPeriodYear: number;
  selectedPayPeriod: PayPeriod;
  payPeriodWeeks: number[][];
  selectedTimeSheetUser: any;
}

export const INITIAL_TEAM_NAVIGATION: TeamNavigation = {
  expanded: new Set([Section.DIRECTORY, Section.PEOPLE, Section.TIME_SHEET]),
  activeView: View.DIRECTORY,
  activeGroup: baseUserGroup,
  activePath: [baseUserGroup],
  activeTimeSheetGroup: {},
  payPeriodYear: initialPayPeriodYear,
  selectedPayPeriod: null,
  payPeriodWeeks: [],
  selectedTimeSheetUser: null,
};

interface Data {
  // teamNavigation saves the state of the teams portal UI
  teamNavigation: TeamNavigation;
  setTeamNavigation: (input: Partial<TeamNavigation>) => void;
}

export const useTeamNavigation = (): Data => {
  const dispatch = useDispatch();

  const teamNavigation = useSelector(
    (state: RootState) => state.teamNavigation,
  );

  const setTeamNavigation = (newTeamNavigation: Partial<TeamNavigation>) => {
    dispatch(updateTeamNavigation({ ...teamNavigation, ...newTeamNavigation }));
  };

  return {
    teamNavigation,
    setTeamNavigation,
  };
};
