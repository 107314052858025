import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'client';

import { retrieveInventorySchemas } from '@atom/actions/inventorySchemaActions';
import { inventorySchemas } from '@atom/selectors/schemaSelectors';
import { InventorySchemaItem } from '@atom/types/inventory';

export interface Data {
  schemas: InventorySchemaItem[];
  loading: boolean;
}

export interface WithSchemasProps {
  schemas: InventorySchemaItem[];
  loadingSchemas: boolean;
}

export const useSchemas = (): Data => {
  const dispatch = useDispatch();
  const schemas = useSelector(inventorySchemas);
  const loading = useSelector(
    (state: RootState) => state.loading.loadingInventorySchemas,
  );

  useEffect(() => {
    if (!schemas?.length && !loading) {
      dispatch(retrieveInventorySchemas({ rootSchemas: true }));
    }
  }, []);

  return { schemas, loading };
};

export function withSchemas<T>(
  WrappedComponent: React.ComponentType<T & WithSchemasProps>,
): React.FC<Omit<T, 'schemas' | 'loadingSchemas'>> {
  return (props: T) => {
    const { schemas, loading } = useSchemas();

    return (
      <WrappedComponent {...props} schemas={schemas} loadingSchemas={loading} />
    );
  };
}
