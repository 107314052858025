import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'client';
import moment from 'moment';
import * as R from 'ramda';

import { updateWorkOrdersInput } from '@atom/actions/workOrdersInputActions';
import { ExportFilter } from '@atom/types/export';
import { WorkOrdersConnectionInput } from '@atom/types/work';
import { DateFilterPresetsCart } from '@atom/types/workFilters';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export enum WorkOrdersView {
  LIST = 'list',
  CALENDAR = 'calendar',
}

export enum CalendarView {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}

export type WorkOrdersInputState = WorkOrdersConnectionInput & {
  // Denotes if default filters have been loaded into the current session
  loadedDefault?: boolean;
  defaultFilter?: any;
  // FE state
  view?: WorkOrdersView;
  // WorkOrderId of the selected work to be shown in the preview tab
  workOrderId?: string;
  // Denotes whether filters is minimized or not
  expanded?: boolean;
  calendarView?: CalendarView;
  calendarViewDate?: number;
  // Selected Date Ranges used to preset date filter values
  dateFilterPresetsCart?: DateFilterPresetsCart;
};

export const FE_PARAMS: Array<keyof WorkOrdersInputState> = [
  'loadedDefault',
  'defaultFilter',
  'view',
  'workOrderId',
  'expanded',
  'calendarView',
  'calendarViewDate',
  'dateFilterPresetsCart',
];

export const EXPORT_FILTER_OMITTED_PARAMS: Array<keyof WorkOrdersInputState> = [
  ...FE_PARAMS,
  'page',
  'limit',
  'sortBy',
];

interface Data {
  // entire work state including FE specific params
  input: WorkOrdersInputState;
  // setter for entire work state
  setInput: (input: WorkOrdersInputState) => void;
  // state parsed and filtered for work GQL query
  workOrdersInput: WorkOrdersConnectionInput;
  // state parsed and filtered for work export projection options GQL query
  workExportFilters: ExportFilter;
}

const getCalendarFilters = (
  view: CalendarView,
  viewDate: number,
): { calendarStart: number; calendarEnd: number } => {
  switch (view) {
    case CalendarView.MONTH: {
      return {
        calendarStart: moment(viewDate).startOf(CalendarView.MONTH).valueOf(),
        calendarEnd: moment(viewDate).endOf(CalendarView.MONTH).valueOf(),
      };
    }
    case CalendarView.WEEK: {
      return {
        calendarStart: moment(viewDate).startOf(CalendarView.WEEK).valueOf(),
        calendarEnd: moment(viewDate).endOf(CalendarView.WEEK).valueOf(),
      };
    }
    case CalendarView.DAY: {
      return {
        calendarStart: moment(viewDate).startOf(CalendarView.DAY).valueOf(),
        calendarEnd: moment(viewDate).endOf(CalendarView.DAY).valueOf(),
      };
    }
    default: {
      return null;
    }
  }
};

export const INITIAL_SETTINGS = {
  loadedDefault: false,
  defaultFilter: {},
  view: WorkOrdersView.LIST,
  workOrderId: '',
  expanded: true,
  calendarView: CalendarView.MONTH,
  calendarViewDate: new Date().valueOf(),
  dateFilterPresetsCart: { workDatePresets: {}, taskDatePresets: {} },
};

export const INITIAL_INPUT = {
  ...INITIAL_SETTINGS,
  page: 1,
  limit: 25,
  sortBy: 'name,asc',
  priorityIds: [],
  statusIds: [],
  categoryIds: [],
  workTemplateIds: [],
  workTemplateFolderIds: [],
  userIds: [],
  groupIds: [],
  completedByIds: [],
  fields: [],
  tasks: {},
};

const TYPENAME = '__typename';

export const removeTypeName = (item: Object) => {
  if (!item) {
    return {};
  }

  return R.omit([TYPENAME], item);
};

export const cleanTypeName = (input: WorkOrdersInputState) => {
  return {
    ...removeTypeName(input),
    fields: input?.fields?.map(field => removeTypeName(field)),
    tasks: {
      ...removeTypeName(input?.tasks),
      commonFields: input?.tasks?.commonFields?.map(field =>
        removeTypeName(field),
      ),
      each: input?.tasks?.each?.map(each => {
        return {
          ...removeTypeName(each),
          fields: each?.fields?.map(field => removeTypeName(field)),
        };
      }),
    },
  };
};

export const useWorkOrdersFilters = (): Data => {
  const dispatch = useDispatch();

  const input = useSelector((state: RootState) => state.workOrdersInput);
  const setInput = (newInput: WorkOrdersInputState) =>
    dispatch(updateWorkOrdersInput(cleanTypeName(newInput)));

  const workOrdersInput = useMemo(() => {
    const isList = input.view === WorkOrdersView.LIST;

    const calendarFilters = getCalendarFilters(
      input.calendarView,
      input.calendarViewDate,
    );

    const dueDateStartFilter = !input.dueDateStart
      ? calendarFilters.calendarStart
      : input.dueDateStart < calendarFilters.calendarStart
      ? calendarFilters.calendarStart
      : input.dueDateStart;

    const dueDateEndFilter = !input.dueDateEnd
      ? calendarFilters.calendarEnd
      : input.dueDateEnd > calendarFilters.calendarEnd
      ? calendarFilters.calendarEnd
      : input.dueDateEnd;

    return R.omit(
      FE_PARAMS,
      R.reject(isNilOrEmpty, {
        ...input,
        limit: isList ? input.limit : 250,
        dueDateStart: isList ? input.dueDateStart : dueDateStartFilter,
        dueDateEnd: isList ? input.dueDateEnd : dueDateEndFilter,
      }),
    );
  }, [input]);

  const workExportFilters = useMemo(() => {
    return R.omit(EXPORT_FILTER_OMITTED_PARAMS, R.reject(isNilOrEmpty, input));
  }, [input]);

  return {
    // @ts-ignore
    input,
    setInput,
    // @ts-ignore
    workOrdersInput,
    // @ts-ignore
    workExportFilters,
  };
};
