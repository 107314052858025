import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

// @ts-ignore
import lineTypeIcon from '@atom/components/common/svgIcons/lineTypeIcon.svg';
import { Icon, ListTable, Menu } from '@atom/mui';
import { Schema } from '@atom/types/schema';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

const { MenuItem } = Menu;
const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

const tableFooterOffset = '3.3rem';

const styles = {
  tableContainer: {
    height: `calc(100% - ${tableFooterOffset})`,
  },
  link: {
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5rem',
  },
  optionsMenuIcon: {
    marginRight: '1rem',
  },
  iconTextContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5rem',
  },
  emptyContent: {
    marginLeft: '0.5rem',
  },
};

interface Props {
  schemas: Partial<Schema>[];
  schemaCount: number;
  handleDuplicateSchema: (schemaId: string) => void;
  setSchemaToDelete: Dispatch<SetStateAction<Partial<Schema>>>;
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
  isSortAscending: 'asc' | 'desc';
  setIsSortAscending: Dispatch<SetStateAction<'asc' | 'desc'>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
  resultsPerPageOptions: number[];
}

const SchemaPortalTable = ({
  schemas,
  schemaCount,
  handleDuplicateSchema,
  setSchemaToDelete,
  sortBy,
  setSortBy,
  isSortAscending,
  setIsSortAscending,
  page,
  setPage,
  limit,
  setLimit,
  resultsPerPageOptions,
}: Props) => {
  const locationTypeDisplay = (locationType: string): ReactNode => {
    if (locationType === 'Point') {
      return (
        <div style={styles.iconTextContainer}>
          <Icon>location_on</Icon>
          Point
        </div>
      );
    } else if (locationType === 'LineString') {
      return (
        <div style={styles.iconTextContainer}>
          <img src={lineTypeIcon} />
          Line
        </div>
      );
    }
    return <div style={styles.emptyContent}>-</div>;
  };

  const handleSortChange = (sortField: string, direction: 'asc' | 'desc') => {
    setSortBy(sortField);
    setIsSortAscending(direction);
  };

  return (
    <ListTable TableContainerProps={{ style: styles.tableContainer }}>
      <TableHead>
        <TableRow header>
          <TableCell
            variant="head"
            sortDirection={sortBy === 'name' ? isSortAscending : false}
            onSortChange={direction => handleSortChange('name', direction)}
          >
            Name
          </TableCell>
          <TableCell
            variant="head"
            sortDirection={
              // Note: the actual backend field is just 'published' where the
              // field the gateway api uses is 'isPublished'
              sortBy === 'published' ? isSortAscending : false
            }
            onSortChange={direction => handleSortChange('published', direction)}
          >
            Status
          </TableCell>
          <TableCell
            variant="head"
            sortDirection={sortBy === 'locationType' ? isSortAscending : false}
            onSortChange={direction =>
              handleSortChange('locationType', direction)
            }
          >
            Location Type
          </TableCell>
          <TableCell variant="head">Created By</TableCell>
          <TableCell
            variant="head"
            sortDirection={sortBy === 'createdDate' ? isSortAscending : false}
            onSortChange={direction =>
              handleSortChange('createdDate', direction)
            }
          >
            Created On
          </TableCell>
          <TableCell variant="head">Modified By</TableCell>
          <TableCell
            variant="head"
            sortDirection={sortBy === 'updatedDate' ? isSortAscending : false}
            onSortChange={direction =>
              handleSortChange('updatedDate', direction)
            }
          >
            Modified On
          </TableCell>
          <TableCell variant="head">
            {/* Blank for the additional options menu */}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {schemas?.map(schema => (
          <TableRow key={schema.id}>
            <TableCell>
              <Link style={styles.link} to={`inventoryTypes/${schema.id}`}>
                {schemaUtilities.getSchemaIconFromSchemaOrAsset(schema)}
                {schema.name}
              </Link>
            </TableCell>
            <TableCell>
              <div style={styles.iconTextContainer}>
                <Icon>{schema.isPublished ? 'lock' : 'edit'}</Icon>
                {schema.isPublished ? 'Published' : 'Draft'}
              </div>
            </TableCell>
            <TableCell>{locationTypeDisplay(schema.locationType)}</TableCell>
            <TableCell>
              {schema.createdBy?.firstName} {schema.createdBy?.lastName}
            </TableCell>
            <TableCell>{setDisplayDate(schema.createdDate)}</TableCell>
            <TableCell>
              {schema.updatedBy?.firstName} {schema.updatedBy?.lastName}
            </TableCell>
            <TableCell>{setDisplayDate(schema.updatedDate)}</TableCell>
            <TableCell>
              {(schema.isPublished || hasRolePermissions(ROLE_SETS.ADMIN)) && (
                <Menu IconButtonProps={{ style: styles.optionsMenuIcon }}>
                  {schema.isPublished && (
                    <MenuItem
                      startAdornment={<Icon>content_copy</Icon>}
                      onClick={() => handleDuplicateSchema(schema.id)}
                    >
                      Duplicate
                    </MenuItem>
                  )}
                  {hasRolePermissions(ROLE_SETS.ADMIN) && (
                    <MenuItem
                      startAdornment={<Icon>delete</Icon>}
                      onClick={() => setSchemaToDelete(schema)}
                    >
                      Delete
                    </MenuItem>
                  )}
                </Menu>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={resultsPerPageOptions}
            count={schemaCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={nextPage => setPage(nextPage)}
            onRowsPerPageChange={event => {
              setLimit(+event.target.value);
              setPage(1);
            }}
          />
        </TableRow>
      </TableFooter>
    </ListTable>
  );
};

export default SchemaPortalTable;
